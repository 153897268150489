import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { EmojiEmotions } from '@mui/icons-material';


const ExplanationBox = () => (
//   <Paper 
//     elevation={3}
//     sx={{
//       mb: 6,
//       mt: 4,
//       p: 2,
//       background: '#1e1e1e',
//     }}
//   >
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        mb: 10,
      }}
    >
      {/* <Typography 
        variant="h5" 
        gutterBottom
        sx={{
          fontSize: '2.5rem',
          fontfamily: 'Roboto',
          fontWeight: 'bold',
          fontStyle: 'normal',
          fontSmooth: 'none',
          color: 'primary.secondary',
        }}
      >
       Validate your Ideas early
      </Typography> */}
      <Typography 
        variant="h12"
        gutterBottom
        sx={{
          fontSize: '1.5rem',
          maxWidth: '600px',
          fontweight:'bold',
          lineHeight: 1.2,
          color: 'text.secondary',
        }}
      >
        The place to share your business ideas and get feedback from the community. Discuss yours and others ideas and views on the future on RateMyIdeas.
      </Typography>

    </Box>
    // </Paper>
);

export default ExplanationBox;