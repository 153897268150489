import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Typography,
  Container,
  Box,
  Button,
  TextField,
  Avatar,
  CircularProgress,
  Paper,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
  Zoom
} from '@mui/material';
import { ExpandMore, ExpandLess, Delete } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import { AuthContext } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import IdeaCard from './IdeaCard';

function CommentThread({ comment, onReply, onDelete, depth = 0 }) {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [showReplies, setShowReplies] = useState(depth === 0);
  const { user } = useContext(AuthContext);

  const handleReply = () => {
    if (replyText.trim() !== '') {
      onReply(comment.id, replyText);
      setReplyText('');
      setShowReplyForm(false);
    }
  };

  const toggleReplies = () => {
    setShowReplies(!showReplies);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this comment?')) {
      onDelete(comment.id);
    }
  };

  return (
    <Paper elevation={1} sx={{ p: 2, mb: 2, ml: depth * 2 }}>
      <Box display="flex" alignItems="center" mb={1}>
        <Avatar
          src={comment.author.profilePicture}
          alt={comment.author.name}
          sx={{ mr: 1, width: 24, height: 24 }}
        />
        <Typography variant="subtitle2">{comment.author.name}</Typography>
        {user && user.uid === comment.author.id && (
          <IconButton onClick={handleDelete} color="error" size="small">
            <Delete />
          </IconButton>
        )}
      </Box>
      <Typography variant="body2" paragraph>
        {comment.text}
      </Typography>
      <Box display="flex" alignItems="center">
        <Button size="small" onClick={() => setShowReplyForm(!showReplyForm)}>
          Reply
        </Button>
        {comment.replies && comment.replies.length > 0 && (
          <IconButton size="small" onClick={toggleReplies}>
            {showReplies ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
      </Box>
      {showReplyForm && (
        <Box mt={1}>
          <TextField
            fullWidth
            multiline
            rows={2}
            variant="outlined"
            placeholder="Write a reply"
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleReply}
            sx={{ mt: 1 }}
          >
            Post Reply
          </Button>
        </Box>
      )}
      {showReplies &&
        comment.replies &&
        comment.replies.map((reply) => (
          <CommentThread
            key={reply.id}
            comment={reply}
            onReply={onReply}
            onDelete={onDelete}
            depth={depth + 1}
          />
        ))}
    </Paper>
  );
}

export default function IdeaPage() {
  const { ideaId } = useParams();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const [idea, setIdea] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newComment, setNewComment] = useState('');
  const explanationRef = useRef(null);
  const commentsRef = useRef(null);
   // States for the Rating Dialog
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(5);
  const [hasRated, setHasRated] = useState(false); // To track if the user has rated
  const [averageRating, setAverageRating] = useState(0);




  // Functions to open and close the dialog
  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleSubmitRating = async () => {
    if (hasRated) {
      alert("You've already rated this idea.");
      return;
    }
  
    if (!user) {
      alert("You need to be logged in to rate.");
      return;
    }
  
    try {
      const ideaRef = doc(db, 'ideas', ideaId);
  
      // Update the rating in Firestore
      await updateDoc(ideaRef, {
        [`ratings.${user.uid}`]: rating // Use the rating state
      });
  
      setHasRated(true); // Mark that the user has rated
      alert("Rating submitted successfully.");
      handleCloseDialog(); // Close the dialog after submission
    } catch (error) {
      console.error("Error updating rating:", error);
    }
  };

  useEffect(() => {
    const fetchIdea = async () => {
      const ideaDoc = await getDoc(doc(db, 'ideas', ideaId));
      if (ideaDoc.exists()) {
        const ideaData = ideaDoc.data();
        setIdea({ id: ideaDoc.id, ...ideaData });
  
        // Calculate the average rating
        const ratings = ideaData.ratings || {};
        const totalRatings = Object.values(ratings).reduce((acc, rating) => acc + rating, 0);
        const averageRating = Object.keys(ratings).length > 0 ? totalRatings / Object.keys(ratings).length : 0;
  
        setAverageRating(averageRating.toFixed(1)); // Store average rating to one decimal place
  
        // Check if the user has already rated this idea
        if (ratings[user.uid] !== undefined) {
          setHasRated(true);
          setRating(ratings[user.uid]); // Set rating to user's previous rating
        }
      }
      setLoading(false);
    };
    fetchIdea();
  }, [ideaId, user]);
  

  const handleAddComment = async (parentCommentId = null, commentText = newComment) => {
    if (!user || commentText.trim() === '') return;

    const newCommentObj = {
      id: Date.now().toString(),
      text: commentText,
      author: {
        id: user.uid,
        name: user.displayName,
        profilePicture: user.photoURL
      },
      createdAt: new Date(),
      replies: []
    };

    const ideaRef = doc(db, 'ideas', ideaId);
    try {
      let updatedComments;
      if (parentCommentId) {
        updatedComments = addReplyToComment(idea.comments || [], parentCommentId, newCommentObj);
      } else {
        updatedComments = [...(idea.comments || []), newCommentObj];
      }
      
      await updateDoc(ideaRef, { comments: updatedComments });
      setIdea(prevIdea => ({ ...prevIdea, comments: updatedComments }));
      setNewComment('');
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    if (!user) return;

    const deleteComment = (comments, id) => {
      return comments.filter(comment => {
        if (comment.id === id) {
          return false;
        }
        if (comment.replies) {
          comment.replies = deleteComment(comment.replies, id);
        }
        return true;
      });
    };

    const updatedComments = deleteComment(idea.comments || [], commentId);
    const ideaRef = doc(db, 'ideas', ideaId);

    try {
      await updateDoc(ideaRef, { comments: updatedComments });
      setIdea(prevIdea => ({ ...prevIdea, comments: updatedComments }));
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const addReplyToComment = (comments, parentCommentId, newReply) => {
    return comments.map(comment => {
      if (comment.id === parentCommentId) {
        return { ...comment, replies: [...(comment.replies || []), newReply] };
      }
      if (comment.replies) {
        return { ...comment, replies: addReplyToComment(comment.replies, parentCommentId, newReply) };
      }
      return comment;
    });
  };

  if (loading) return <CircularProgress />;
  if (!idea) return <Typography>Idea not found</Typography>;



  return (
    <Container maxWidth="md">
        <IdeaCard idea={idea} showExplanationButton={false} />
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom ref={explanationRef}>
            Explanation
          </Typography>
          <Typography variant="body1" paragraph>
            {idea.explanation}
          </Typography>
        </Paper>

        <Typography variant="h6" gutterBottom ref={commentsRef}>
          Comments
        </Typography>
        {idea.comments &&
          idea.comments.map((comment) => (
            <CommentThread
              key={comment.id}
              comment={comment}
              onReply={handleAddComment}
              onDelete={handleDeleteComment}
            />
          ))}

        {user && (
          <Box mt={3} position="relative">
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="Add a comment"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => handleAddComment()}
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}

        {/* Centered button for opening the rating dialog */}
        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <Button variant="contained" color="primary" size="large" onClick={handleOpenDialog}>
            Rate Idea
          </Button>
        </Box>

        {/* Dialog for rating */}
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          maxWidth="sm" // Makes the dialog wider
          fullWidth // Expands the dialog to full width within the maxWidth constraint
          TransitionComponent={Zoom} // Enables scaling animation
        >
          <DialogTitle>Rate this Idea</DialogTitle>
          <DialogContent>
            <Slider
              value={rating}
              onChange={handleRatingChange}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="on" // Shows the slider value while toggling
              step={1}
              marks
              min={0}
              max={10}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmitRating} color="primary">
              Submit
            </Button>

          </DialogActions>
        </Dialog>
    </Container>
  );
}
