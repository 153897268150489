import React, { useState, useEffect, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  Typography, Container, Button, TextField, Avatar, CircularProgress,
  Grid, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Snackbar,
  Box, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction,
  FormControl, InputLabel, Select, MenuItem, ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import IdeaCard from './IdeaCard';
import IdeaSubmissionForm, { categories } from './IdeaSubmissionForm';
import { AuthContext } from '../contexts/AuthContext';
import { storage, db } from '../firebase';
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { doc, getDoc, setDoc, deleteDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getAuth, updateProfile } from 'firebase/auth'; 

export default function Profile({ handleAuthAction }) {
  const { userId } = useParams();
  const { user } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [profileUser, setProfileUser] = useState(null);
  const [username, setUsername] = useState('');
  const [bio, setBio] = useState('');
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState(null); // State to store the profile picture URL
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userIdeas, setUserIdeas] = useState([]);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followerCount, setFollowerCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [showFollowDialog, setShowFollowDialog] = useState(false);
  const [followDialogType, setFollowDialogType] = useState('');
  const [followList, setFollowList] = useState([]);
  const [followingStatus, setFollowingStatus] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [ideas, setIdeas] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortMethod, setSortMethod] = useState('recent');





  useEffect(() => {
    async function fetchUserProfile() {
      if (!userId) {
        setLoading(false);
        return;
      }
      try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          const userData = { id: userDoc.id, ...userDoc.data() };
          setProfileUser(userData);
          setUsername(userData.displayName || '');
          setBio(userData.bio || '');
          setProfilePictureURL(userData.photoURL || null);
          setFollowerCount(userData.followers?.length || 0);
          setFollowingCount(userData.following?.length || 0);
 
          // Fetch the profile picture URL if it exists
          if (userData.profilePicture) {
            const fileRef = ref(storage, `profilePictures/${userData.profilePicture}`);
            try {
              const url = await getDownloadURL(fileRef);
              setProfilePictureURL(url);
            } catch (error) {
              console.error('Error fetching profile picture URL:', error);
              setProfilePictureURL(null); // Handle the case where the URL could not be fetched
            }
          }
        } else {
          setError("User not found");
        }
        await fetchUserIdeas(userId);
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setError("Failed to fetch user profile.");
      } finally {
        setLoading(false);
      }
    }

    async function fetchUserIdeas(userId) {
      try {
        const ideasQuery = query(collection(db, 'ideas'), where('author.id', '==', userId));
        const ideasSnapshot = await getDocs(ideasQuery);
        const ideasData = ideasSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setIdeas(ideasData);
      } catch (error) {
        console.error("Error fetching user ideas:", error);
        setError("Failed to fetch user ideas.");
      }
    }

    fetchUserProfile();
  }, [userId]); // Trigger whenever userId changes

  const handleFileChange = (event) => {
    if (event.target.files[0]) {
      setProfilePicture(event.target.files[0]);
    }
  };

  const handleSave = async () => {
    setError(null); // Clear any previous error
    let photoURL = profileUser.photoURL; // Default to the current photo URL

    // Upload new profile picture if provided
    if (profilePicture) {
      try {
        const fileRef = ref(storage, `profilePictures/${userId}`);
        await uploadBytes(fileRef, profilePicture);
        photoURL = await getDownloadURL(fileRef);
      } catch (error) {
        console.error("Error uploading profile picture:", error);
        setError("Failed to upload profile picture.");
        return;
      }
    }

    const updatedProfile = { displayName: username, bio, photoURL };

    try {
      const auth = getAuth(); // Get the Firebase Auth instance
      const currentUser = auth.currentUser; // Get the currently authenticated user

      if (!currentUser) {
        setError("User is not authenticated.");
        return;
      }

      // Use Firebase's updateProfile function
      await updateProfile(currentUser, { displayName: username, photoURL }); // Update Firebase Auth profile

      // Update Firestore user document
      await setDoc(doc(db, 'users', userId), updatedProfile, { merge: true });

      // Update local state with the new profile information
      setProfileUser((prevState) => ({ ...prevState, ...updatedProfile }));
      setProfilePictureURL(photoURL); // Update the profile picture URL in state
      setIsEditing(false); // Exit editing mode
    } catch (error) {
      console.error("Error updating profile:", error);
      setError("Failed to update profile.");
    }
  };

  const handleDeleteIdea = async (ideaId) => {
    try {
      await deleteDoc(doc(db, 'ideas', ideaId));
      setUserIdeas(prevIdeas => prevIdeas.filter(idea => idea.id !== ideaId));
    } catch (error) {
      console.error("Error deleting idea:", error);
      setError("Failed to delete idea.");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!profileUser) {
    return <Typography>User not found</Typography>;
  }

  const isOwnProfile = user && user.uid === userId;

  const handleFollowUser = async () => {
    if (!user) {
      setError("You must be logged in to follow users.");
      return;
    }

    try {
      const currentUserRef = doc(db, 'users', user.uid);
      const targetUserRef = doc(db, 'users', userId);

      if (isFollowing) {
        // Unfollow
        await updateDoc(currentUserRef, {
          following: arrayRemove(userId)
        });
        await updateDoc(targetUserRef, {
          followers: arrayRemove(user.uid)
        });
        setIsFollowing(false);
        setFollowerCount(prev => prev - 1);
      } else {
        // Follow
        await updateDoc(currentUserRef, {
          following: arrayUnion(userId)
        });
        await updateDoc(targetUserRef, {
          followers: arrayUnion(user.uid)
        });
        setIsFollowing(true);
        setFollowerCount(prev => prev + 1);
      }
    } catch (error) {
      console.error("Error updating follow status:", error);
      setError("Failed to update follow status.");
    }
  };

  const handleShowFollowDialog = async (type) => {
      setFollowDialogType(type);
      setShowFollowDialog(true);
      setFollowList([]);

      try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        const userData = userDoc.data();
        const userIds = type === 'followers' ? userData.followers : userData.following;

        if (userIds && userIds.length > 0) {
          const userPromises = userIds.map(id => getDoc(doc(db, 'users', id)));
          const userDocs = await Promise.all(userPromises);
          const users = userDocs.map(doc => ({ id: doc.id, ...doc.data() }));
          setFollowList(users);
           // Initialize following status for each user
         const statusObj = {};
         users.forEach(u => {
           statusObj[u.id] = u.followers?.includes(user.uid) || false;
         });
         setFollowingStatus(statusObj);
      }}
               
       catch (error) {
        console.error(`Error fetching ${type}:`, error);
        setError(`Failed to fetch ${type}.`);
      }
  };

  const handleFollowUserFromDialog = async (targetUserId) => {
    if (!user) {
      setError("You must be logged in to follow users.");
      return;
    }

    try {
      const currentUserRef = doc(db, 'users', user.uid);
      const targetUserRef = doc(db, 'users', targetUserId);
      const isCurrentlyFollowing = followingStatus[targetUserId];

      if (isCurrentlyFollowing) {
        // Unfollow
        await updateDoc(currentUserRef, {
          following: arrayRemove(targetUserId)
        });
        await updateDoc(targetUserRef, {
          followers: arrayRemove(user.uid)
        });
      } else {
        // Follow
        await updateDoc(currentUserRef, {
          following: arrayUnion(targetUserId)
        });
        await updateDoc(targetUserRef, {
          followers: arrayUnion(user.uid)
        });
      }

      // Update local state
      setFollowingStatus(prev => ({
        ...prev,
        [targetUserId]: !isCurrentlyFollowing
      }));

      // Update follower/following counts if necessary
      if (targetUserId === userId) {
        setFollowerCount(prev => isCurrentlyFollowing ? prev - 1 : prev + 1);
      }
      if (user.uid === userId) {
        setFollowingCount(prev => isCurrentlyFollowing ? prev - 1 : prev + 1);
      }

    } catch (error) {
      console.error("Error updating follow status:", error);
      setError("Failed to update follow status.");
    }
  };


  const handleSort = (event) => {
    setSortMethod(event.target.value);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };


  const sortIdeas = (ideasToSort) => {
    switch (sortMethod) {
      case 'recent':
        return [...ideasToSort].sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
      case 'highestRated':
        return [...ideasToSort].sort((a, b) => {
          const aRatings = a.ratings ? Object.values(a.ratings) : [];
          const bRatings = b.ratings ? Object.values(b.ratings) : [];
          const aAvgRating = aRatings.length > 0 ? aRatings.reduce((sum, rating) => sum + rating, 0) / aRatings.length : 0;
          const bAvgRating = bRatings.length > 0 ? bRatings.reduce((sum, rating) => sum + rating, 0) / bRatings.length : 0;
          return bAvgRating - aAvgRating;
        });
      default:
        return ideasToSort;
    }
  };

  const filteredIdeas = sortIdeas(ideas.filter(idea =>
    (idea.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    idea.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (!selectedCategory || idea.category === selectedCategory) &&
    (!selectedSubcategory || idea.subcategory === selectedSubcategory)
  ));


 


  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>

        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Avatar src={profilePictureURL} alt={profileUser.displayName || 'Unknown'} sx={{ width: 100, height: 100 }} />
              </Grid>
              <Grid item xs>
                <Typography variant="h4" component="h1">
                  {profileUser.displayName || 'User'}
                </Typography>
                <Typography variant="body1">
                  {profileUser.bio || 'No bio yet'}
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Button 
                    variant="text" 
                    onClick={() => handleShowFollowDialog('followers')}
                    sx={{ mr: 2, textTransform: 'none' }}
                  >
                    <strong>{followerCount}</strong> {followerCount === 1 ? 'follower' : 'followers'}
                  </Button>
                  <Button 
                    variant="text" 
                    onClick={() => handleShowFollowDialog('following')}
                    sx={{ textTransform: 'none' }}
                  >
                    <strong>{followingCount}</strong> following
                  </Button>
                  </Box>
                  {/* Follow Dialog */}
                  <Dialog open={showFollowDialog} onClose={() => setShowFollowDialog(false)} maxWidth="sm" fullWidth>
                    <DialogTitle>
                      {followDialogType === 'followers' ? 'Followers' : 'Following'}
                    </DialogTitle>
                    <DialogContent>
                      <List>
                        {followList.map((followUser) => (
                          <ListItem key={followUser.id} alignItems="flex-start">
                            <ListItemAvatar>
                              <Avatar src={followUser.photoURL} alt={followUser.displayName || 'User'} />
                            </ListItemAvatar>
                            <ListItemText 
                              primary={
                                <Link to={`/profile/${followUser.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                  {followUser.displayName || 'User'}
                                </Link>
                              } 
                              secondary={followUser.bio}
                            />
                            {user && user.uid !== followUser.id && (
                              <ListItemSecondaryAction>
                                <Button
                                  variant="outlined"
                                  color={followingStatus[followUser.id] ? "secondary" : "primary"}
                                  onClick={() => handleFollowUserFromDialog(followUser.id)}
                                >
                                  {followingStatus[followUser.id] ? 'Unfollow' : 'Follow'}
                                </Button>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        ))}
                      </List>
                      {followList.length === 0 && (
                        <Typography align="center">
                          No {followDialogType} to display.
                        </Typography>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setShowFollowDialog(false)}>Close</Button>
                    </DialogActions>
                  </Dialog>
              </Grid>
              {isOwnProfile ? (
                <Grid item>
                  <Button onClick={() => setIsEditing(true)} variant="outlined" color="primary">
                    Edit Profile
                  </Button>
                </Grid>
              ): (
                <Grid item>
                <Button 
                  onClick={handleFollowUser} 
                  variant="outlined" 
                  color={isFollowing ? "secondary" : "primary"}
                >
                  {isFollowing ? 'Unfollow' : 'Follow'}
                </Button>
              </Grid>
                )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            {isOwnProfile ? 'Your Ideas' : `${profileUser.displayName}'s Ideas`}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Box sx={{ display: 'flex' }}>
                <FormControl variant="outlined" sx={{ minWidth: 120, mr: 2 }}>
                  <InputLabel id="category-select-label">Category</InputLabel>
                  <Select
                    labelId="category-select-label"
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      setSelectedSubcategory('');
                    }}
                    label="Category"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {Object.keys(categories).map((category) => (
                      <MenuItem key={category} value={category}>{category}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" sx={{ minWidth: 140 }} disabled={!selectedCategory}>
                  <InputLabel id="subcategory-select-label">Subcategory</InputLabel>
                  <Select
                    labelId="subcategory-select-label"
                    value={selectedSubcategory}
                    onChange={(e) => setSelectedSubcategory(e.target.value)}
                    label="Subcategory"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {selectedCategory && categories[selectedCategory].map((subcategory) => (
                      <MenuItem key={subcategory} value={subcategory}>{subcategory}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              
              <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <InputLabel id="sort-select-label">Sort By</InputLabel>
                <Select
                  labelId="sort-select-label"
                  value={sortMethod}
                  onChange={handleSort}
                  label="Sort By"
                >
                  <MenuItem value="recent">Most Recent</MenuItem>
                  <MenuItem value="highestRated">Best Rated</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <TextField 
                fullWidth
                variant="outlined"
                placeholder="Search ideas..." 
                value={searchTerm}
                onChange={handleSearch}
                sx={{ mb: 2 }}
              />
          {filteredIdeas.length > 0 ? (
            filteredIdeas.map(idea => (
              <IdeaCard 
                key={idea.id} 
                idea={idea} 
                onDelete={handleDeleteIdea}
              />
            ))
          ) : (
            <Typography>No ideas submitted yet.</Typography>
          )}
        </Grid>
      </Grid>

      <Dialog open={isEditing} onClose={() => setIsEditing(false)}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Bio"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={bio}
            onChange={(e) => setBio(e.target.value)}
          />
          <input
            accept="image/*"
            type="file"
            onChange={handleFileChange}
            style={{ marginTop: '1rem' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Container>
  );
}
