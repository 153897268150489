// components/IdeaSubmissionForm.js
import React, { useState, useContext, useEffect} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';



export const categories = {
  'Technology': ['Software', 'Hardware', 'AI/ML', 'Blockchain', 'IoT'],
  'Finance': ['Banking', 'Investment', 'Insurance', 'Fintech', 'Cryptocurrency'],
  'Healthcare': ['Telemedicine', 'Medical Devices', 'Biotechnology', 'Mental Health', 'Fitness'],
  'Education': ['E-learning', 'EdTech', 'Language Learning', 'Professional Development', 'K-12'],
  'E-commerce': ['B2C', 'B2B', 'C2C', 'D2C', 'Subscription Services'],
  'Sustainability': ['Renewable Energy', 'Recycling', 'Sustainable Agriculture', 'Green Transportation', 'Eco-friendly Products'],
  'Entertainment': ['Streaming Services', 'Gaming', 'Virtual Reality', 'Social Media', 'Live Events'],
  'Food & Beverage': ['Restaurant Tech', 'Food Delivery', 'Meal Kits', 'Plant-based Foods', 'Beverage Innovation'],
  'Travel': ['Hospitality Tech', 'Travel Planning', 'Transportation', 'Adventure Tourism', 'Business Travel'],
  'Real Estate': ['PropTech', 'Smart Homes', 'Property Management', 'Co-living', 'Commercial Real Estate']
};



export default function IdeaSubmissionForm({ onClose, onSubmit }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [explanation, setExplanation] = useState('');
  const [category, setCategory] = useState('business');
  const [subcategory, setSubcategory] = useState('');
  const { user } = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      category,
      title,
      description,
      explanation,
      author: user.uid,
      votes: 0,
      comments: []
    });
    onClose();
  };

  useEffect(() => {
    // Reset subcategory when category changes
    setSubcategory('');
  }, [category]);


 

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Submit New Idea</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>

        <FormControl fullWidth margin="dense">
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              value={category}
              label="Category"
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              {Object.keys(categories).map((cat) => (
                <MenuItem key={cat} value={cat}>{cat}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="dense">
            <InputLabel id="subcategory-label">Subcategory</InputLabel>
            <Select
              labelId="subcategory-label"
              value={subcategory}
              label="Subcategory"
              onChange={(e) => setSubcategory(e.target.value)}
              required
              disabled={!category}
            >
              {category && categories[category] && categories[category].map((subcat) => (
                <MenuItem key={subcat} value={subcat}>{subcat}</MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <TextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={2}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            label="Explanation"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={explanation}
            onChange={(e) => setExplanation(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}