import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Button, IconButton, Avatar, Box, Paper } from '@mui/material';
import { Delete } from '@mui/icons-material';
import Login from './Login'; // Ensure this import path is correct
import { AuthContext } from '../contexts/AuthContext'; // Adjust the import path as needed
import { doc, getDoc } from 'firebase/firestore';
import { db, storage } from '../firebase';
import { ref, getDownloadURL } from 'firebase/storage';


export default function IdeaCard({ idea, onVote, onComment, showExplanationButton = true, onDelete}) {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [showLogin, setShowLogin] = useState(false);
  const [authorName, setAuthorName] = useState('Unknown Author');
  const [profilePictureURL, setProfilePictureURL] = useState(null);

  useEffect(() => {
    const fetchAuthorInfo = async () => {
      if (idea.author?.id) {
        try {
          const userDoc = await getDoc(doc(db, 'users', idea.author.id));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setAuthorName(userDoc.data().displayName || 'Unknown Author');
            setProfilePictureURL(userData.photoURL || null);
          }
        } catch (error) {
          console.error("Error fetching author name:", error);
        }
      }
    };

    fetchAuthorInfo();
  }, [idea.author?.id]);


  if (!idea) {
    return <Typography>Error: Idea data is missing</Typography>;
  }

  const ShowLoginModal = () => {
    setShowLogin(true);
  };

  const handleExplanationClick = (e) => {
    e.stopPropagation();
    if (user) {
      navigate(`/idea/${idea.id}`, { state: { scrollToExplanation: true } });
    } else {
      ShowLoginModal();
    }
  };

  const handleProfileClick = (e) => {
    e.stopPropagation();
    navigate(`/profile/${idea.author.id}`);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to delete this idea?')) {
      onDelete(idea.id);
    }
  };

  

  return (
    <Paper 
      elevation={3}
      sx={{
        mb: 6,
        mt: 4,
        p: 4,
        borderRadius: '10px',
        border: '1px solid rgba(255, 255, 255, 0.3)',
        borderColor: 'primary.main'
      }}
    >
      <Card sx={{ mb: 2, cursor: 'pointer' }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box display="flex" alignItems="center">
              <Button onClick={handleProfileClick}>
                <Avatar  src={profilePictureURL} alt={idea.author?.name || 'Unknown'} sx={{ mr: 1 }} />
              </Button>
              {/* <Typography variant="subtitle1">{idea.author?.id || 'Unknown Author'}</Typography> */}
              <Typography variant="subtitle1">{authorName}</Typography>

            </Box>
            <Box>
              {user && user.uid === idea.author.id && (
                <IconButton onClick={handleDelete} color="error">
                  <Delete />
                </IconButton>
              )}
            </Box>
          </Box>
          <Typography variant="h5" component="div">
            {idea.title || 'Untitled Idea'}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {idea.description || 'No description available'}
          </Typography>
        </CardContent>
      </Card>
      <Box display="flex" justifyContent="flex-end">
        {showExplanationButton && (
          <Button sx={{ cursor: 'pointer' }} onClick={handleExplanationClick}>
            View Explanation and Rate
          </Button>
        )}
      </Box>
      <Login open={showLogin} onClose={() => setShowLogin(false)} />
    </Paper>
  );
}