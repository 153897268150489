// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';





// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

  const firebaseConfig = {
    apiKey: "AIzaSyDVMA7zqSqlKntBfQUT5YsRCM6W44pK7x8",
    authDomain: "ideahunt-329ef.web.app",
    databaseURL: "https://ideahunt-329ef-default-rtdb.firebaseio.com",
    projectId: "ideahunt-329ef",
    storageBucket: "ideahunt-329ef.appspot.com",
    messagingSenderId: "842522705092",
    appId: "1:842522705092:web:451878b4db27f2f4e99d91",
    measurementId: "G-GTFJSSZVF8"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);


