import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { 
   Container, Button, TextField, Box, ThemeProvider, CircularProgress, createTheme, FormControl, InputLabel, Select, MenuItem, ToggleButton,
   ToggleButtonGroup, Typography
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import IdeaSubmissionForm, {categories} from './components/IdeaSubmissionForm';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Header from './components/Header';
import Profile from './components/Profile';
import IdeaCard from './components/IdeaCard';
import PrivateRoute from './components/PrivateRoute';
import IdeaPage from './components/IdeaPage';
import ExplanationBox from './components/ExplanationBox';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import logo from './assets/OriginalOnTransparent.png';
import { db } from './firebase';
import { collection, query, onSnapshot, addDoc, updateDoc, doc, deleteDoc, arrayUnion } from 'firebase/firestore';

// Theme definition
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00c069',
    },
    secondary: {
      main: '#a5c8ff',
    },
    background: {
      default: '#1e1e1e',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#e1e2e6',
      secondary: '#b8c4e0',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#1e1e1e',
          color: '#e1e2e6',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#1e1e1e',
          color: '#e1e2e6',
          bordercolor: '#00c069'
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#e1e2e6',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: '#e1e2e6',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: {
            primary: '#1e1e1e',
            secondary:'#1e1e1e',
          } 
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '#2a2a2a',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#2a2a2a',
        },
      },
    },
  },
});

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSubmissionForm, setShowSubmissionForm] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [ideas, setIdeas] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, loading: authLoading } = useContext(AuthContext);
  const [sortMethod, setSortMethod] = useState('recent'); // New state for sorting
  const [mode, setMode] = useState('allIdeas'); // New state for mode selection
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');


  useEffect(() => {
    const q = query(collection(db, 'ideas'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const ideasArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setIdeas(ideasArray);
      setLoading(false);
    }, (error) => {
      console.error("Error fetching ideas:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleModeChange = (event, newMode) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  const showLoginModal = () => {
    setShowLogin(true);
  };

  const handleSort = (event) => {
    setSortMethod(event.target.value);
  };

  const sortIdeas = (ideasToSort) => {
    switch (sortMethod) {
      case 'recent':
        return [...ideasToSort].sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
      case 'highestRated':
        return [...ideasToSort].sort((a, b) => {
          const aRatings = a.ratings ? Object.values(a.ratings) : [];
          const bRatings = b.ratings ? Object.values(b.ratings) : [];
          const aAvgRating = aRatings.length > 0 ? aRatings.reduce((sum, rating) => sum + rating, 0) / aRatings.length : 0;
          const bAvgRating = bRatings.length > 0 ? bRatings.reduce((sum, rating) => sum + rating, 0) / bRatings.length : 0;
          return bAvgRating - aAvgRating;
        });
      default:
        return ideasToSort;
    }
  };


  const handleSubmitIdea = async (newIdea) => {
    if (!user) {
      showLoginModal();
      return;
    }

    try {
      await addDoc(collection(db, 'ideas'), {
        ...newIdea,
        upvotes: 0,
        downvotes: 0,
        comments: [],
        author: {
          id: user.uid,
          name: user.displayName,
          profilePicture: user.photoURL
        },
        createdAt: new Date()
      });
    } catch (error) {
      console.error("Error submitting idea:", error);
    }
  };

  const handleComment = async (ideaId, comment) => {
    if (!user) {
      showLoginModal();
      return;
    }

    try {
      const ideaRef = doc(db, 'ideas', ideaId);
      await updateDoc(ideaRef, {
        comments: arrayUnion({
          text: comment,
          author: {
            id: user.uid,
            name: user.displayName,
            profilePicture: user.photoURL
          },
          createdAt: new Date()
        })
      });
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleDeleteIdea = async (ideaId) => {
    try {
      await deleteDoc(doc(db, 'ideas', ideaId));
      alert("Idea deleted successfully!");
    } catch (error) {
      console.error("Error deleting idea:", error);
    }
  };

  const filteredIdeas = sortIdeas(ideas.filter(idea =>
    (idea.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    idea.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (!selectedCategory || idea.category === selectedCategory) &&
    (!selectedSubcategory || idea.subcategory === selectedSubcategory)
  ));

  if (authLoading || loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
        <Router>
          <Header onLoginClick={() => setShowLogin(true)} />
          <Container maxWidth="md" sx={{ pt: 4, pb: 4 }}>
            <Routes>
              <Route path="/" element={
                <>
                  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box component="img" src={logo} alt="Business Idea Platform Logo" sx={{ width: '250px', height: 'auto', mb: 4 }} />
                  </Box>

                  <ExplanationBox />

                  {/* Mode selection buttons */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 8 }}>
                    <ToggleButtonGroup
                      value={mode}
                      exclusive
                      onChange={handleModeChange}
                      aria-label="mode selection"
                    >
                      <ToggleButton value="allIdeas" aria-label="all ideas">
                        Ideas
                      </ToggleButton>
                      <ToggleButton value="competitions" aria-label="competitions">
                        Competitions
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>

                  {mode === 'allIdeas' ? (
                    <>
                      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 8}}>
                        <Button 
                          variant="contained" 
                          color="primary"
                          onClick={() => {
                            if (user) {
                              setShowSubmissionForm(true);
                            } else {
                              showLoginModal();
                            }
                          }}
                        >
                          Submit New Idea
                        </Button>
                      </Box>

                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                          <Box sx={{ display: 'flex' }}>
                            <FormControl variant="outlined" sx={{ minWidth: 120, mr: 2 }}>
                              <InputLabel id="category-select-label">Category</InputLabel>
                              <Select
                                labelId="category-select-label"
                                value={selectedCategory}
                                onChange={(e) => {
                                  setSelectedCategory(e.target.value);
                                  setSelectedSubcategory('');
                                }}
                                label="Category"
                              >
                                <MenuItem value="">
                                  <em>All</em>
                                </MenuItem>
                                {Object.keys(categories).map((category) => (
                                  <MenuItem key={category} value={category}>{category}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl variant="outlined" sx={{ minWidth: 140 }} disabled={!selectedCategory}>
                              <InputLabel id="subcategory-select-label">Subcategory</InputLabel>
                              <Select
                                labelId="subcategory-select-label"
                                value={selectedSubcategory}
                                onChange={(e) => setSelectedSubcategory(e.target.value)}
                                label="Subcategory"
                              >
                                <MenuItem value="">
                                  <em>All</em>
                                </MenuItem>
                                {selectedCategory && categories[selectedCategory].map((subcategory) => (
                                  <MenuItem key={subcategory} value={subcategory}>{subcategory}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                          
                          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                            <InputLabel id="sort-select-label">Sort By</InputLabel>
                            <Select
                              labelId="sort-select-label"
                              value={sortMethod}
                              onChange={handleSort}
                              label="Sort By"
                            >
                              <MenuItem value="recent">Most Recent</MenuItem>
                              <MenuItem value="highestRated">Best Rated</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>

                      <TextField 
                        fullWidth
                        variant="outlined"
                        placeholder="Search ideas..." 
                        value={searchTerm}
                        onChange={handleSearch}
                        sx={{ bgcolor: 'background.paper', mb: 2 }}
                      />
                      

                      {filteredIdeas.map((idea) => (
                        <IdeaCard 
                          key={idea.id} 
                          idea={idea} 
                          onComment={handleComment}
                          onDelete={handleDeleteIdea}
                        />
                      ))}
                    </>
                  )
                  : ( 
                  <Box sx={{ textAlign: 'center', mt: 4 }}>
                  <Typography variant="h5">
                    Competitions feature coming soon!
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Stay tuned for exciting business idea competitions.
                  </Typography>
                </Box>
                  )}
                </>
              } />

              <Route path="/login" element={<Login />} />
              <Route element={<PrivateRoute />}>
                <Route 
                  path="/profile/:userId" 
                  element={
                    <Profile 
                      userIdeas={ideas.filter(idea => 
                        idea.author && idea.author.id === user?.uid
                      )} 
                    />
                  } 
                />
              </Route>
              <Route path="/idea/:ideaId" element={<IdeaPage />} />
            </Routes>

            {showSubmissionForm && (
              <IdeaSubmissionForm 
                onClose={() => setShowSubmissionForm(false)} 
                onSubmit={handleSubmitIdea}
              />
            )}

            <Login 
              open={showLogin} 
              onClose={() => setShowLogin(false)}
              onSignUpClick={() => {
                setShowLogin(false);
                setShowSignUp(true);
              }}
            />
            <SignUp 
              open={showSignUp} 
              onClose={() => setShowSignUp(false)} 
            /> 
          </Container>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default function AppWithAuth() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}